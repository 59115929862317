<template>
  <my-progress-base-filter
    title="תקופה"
    :filter-options="filterOptions"
    :selected-option="selectedOption"
    :selected-key="selectedKey"
    @filters-changed="filterSelected"
    @close="$emit('close')"
    />
</template>

<script>
import Segment from '@/Segment';
import { getCurrentCashflowMonth, getCashflowMonthFromMonthsAgo } from '@riseupil/common-utils';
import { mapState } from 'vuex';
import _ from 'lodash';
import MyProgressBaseFilter from './MyProgressBaseFilter.vue';
import { DATE_FILTERS_STRINGS } from './my-progress-filters.consts';

export default {
  name: 'MyProgressDateFilter',
  components: {
    MyProgressBaseFilter,
  },
  props: {
    filterKey: {
      type: String,
      required: true,
    },
    oldestCfMonth: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedKey: null,
    };
  },
  created() {
    this.selectedKey = this.filterKey;
  },
  computed: {
    ...mapState('cashflowView', ['cashflowStartDay']),
    filterOptions() {
      const currentCashflowMonth = getCurrentCashflowMonth(this.cashflowStartDay);
      const previousCashflowMonth = getCashflowMonthFromMonthsAgo(currentCashflowMonth, 1);
      return [
        {
          filterKey: 'last-6-months',
          ...DATE_FILTERS_STRINGS['last-6-months'],
          start: getCashflowMonthFromMonthsAgo(previousCashflowMonth, 5),
          end: previousCashflowMonth,
        },
        {
          filterKey: 'last-12-months',
          ...DATE_FILTERS_STRINGS['last-12-months'],
          start: getCashflowMonthFromMonthsAgo(previousCashflowMonth, 11),
          end: previousCashflowMonth,
        },
        {
          filterKey: '2024',
          ...DATE_FILTERS_STRINGS['2024'],
          start: '2024-01',
          end: '2024-12',
        },
        ...(this.oldestCfMonth < '2024-01' ? [{
          filterKey: '2023',
          ...DATE_FILTERS_STRINGS['2023'],
          start: '2023-01',
          end: '2023-12',
        }] : []),
        ...(this.oldestCfMonth < '2023-01' ? [{
          filterKey: '2022',
          ...DATE_FILTERS_STRINGS['2022'],
          start: '2022-01',
          end: '2022-12',
        }] : []),
      ];
    },
    selectedOption() {
      return _.find(this.filterOptions, option => option.filterKey === this.selectedKey);
    },
  },
  methods: {
    filterSelected(option) {
      if (option === this.selectedOption) {
        return;
      }
      Segment.trackUserInteraction('MyProgress_Date_Filter_Selected', { filterKey: option.filterKey });
      this.selectedKey = option.filterKey;
      this.$emit('filters-changed', option);
    },
  },
};
</script>

<style scoped lang="scss">

</style>
