// eslint-disable-next-line import/prefer-default-export
export const DATE_FILTERS_STRINGS = {
  'last-12-months': {
    label: '12 חודשים אחרונים',
    shortLabel: '12 חוד׳ אחרונים',
  },
  'last-6-months': {
    label: '6 חודשים אחרונים',
    shortLabel: '6 חוד׳ אחרונים',
  },
  2024: {
    label: '2024',
    shortLabel: '2024',
  },
  2023: {
    label: '2023',
    shortLabel: '2023',
  },
  2022: {
    label: '2022',
    shortLabel: '2022',
  },
};

export const INCOME_DATA_SECTION_FILTERS_STRINGS = {
  all: {
    label: 'כל ההכנסות',
    shortLabel: 'כל ההכנסות',
  },
  onlyFixed: {
    label: 'הכנסות קבועות',
    shortLabel: 'הכנסות קבועות',
  },
  onlyVariable: {
    label: 'הכנסות משתנות',
    shortLabel: 'הכנסות משתנות',
  },
};

export const FIXED_DATA_SECTION_FILTERS_STRINGS = {
  all: {
    label: 'כל ההוצאות הקבועות',
    shortLabel: 'כל ההוצאות',
  },
};

export const BALANCE_DATA_SECTION_FILTERS_STRINGS = {
  cashflows: {
    label: 'תזרימים בלבד',
    shortLabel: 'תזרימים',
  },
  cashflowsAndExcluded: {
    label: 'תזרימים כולל עסקאות שהוצאת מהתזרים',
    shortLabel: 'כולל לא בתזרים',
  },
};

export const SAVINGS_DATA_SECTION_FILTERS_STRINGS = {
  cashflows: {
    label: 'תזרימים בלבד',
    shortLabel: 'תזרימים',
  },
  cashflowsAndExcluded: {
    label: 'תזרימים כולל חסכונות שהוצאת מהתזרים',
    shortLabel: 'כולל לא בתזרים',
  },
};
