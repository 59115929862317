<template>
  <bottom-sheet @close="$emit('close')">
    <div class="my-progress-filter" role="radiogroup">
      <div class="ri-medium-bold-headline filter-title">{{ title }}</div>
      <div v-for="option in filterOptions" :key="option.filterKey" class="filter-option ri-label"
           @click="filterChanged(option)" @keyup.enter="filterChanged(option)"
           tabindex="0" role="radio" :class="{ selected: isSelected(option) }">
        {{ option.label }}
      </div>
    </div>
  </bottom-sheet>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import _ from 'lodash';
import Intercom from '@/Intercom';

export default {
  name: 'MyProgressBaseFilter',
  components: {
    BottomSheet: BaseUI.BottomSheet,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    filterOptions: {
      type: Array,
      required: true,
    },
    selectedOption: {
      type: Object,
      required: true,
    },
  },
  created() {
    Intercom.hideLauncher();
  },
  methods: {
    filterChanged(option) {
      this.$emit('filters-changed', option);
    },
    isSelected(option) {
      return _.isEqual(option, this.selectedOption);
    },
    onClose() {
      this.$emit('close');
      Intercom.showLauncher();
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@riseupil/base-ui/src/scss/riseup-colors";
@import "~@riseupil/base-ui/src/scss/spacings";

.my-progress-filter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: $ri-spacing-xs;
  row-gap: $ri-spacing-s;
  padding: $ri-spacing-sm;
  margin-bottom: $ri-spacing-ml;

  .filter-title {
    width: 100%;
    text-align: right;
    margin-bottom: $ri-spacing-ml;
  }

  .filter-option {
    background: $riseup_light_blue;
    color: $riseup_blue;
    padding: $ri-spacing-xxs $ri-spacing-sm;
    border-radius: 20px;
    cursor: pointer;
    &.selected {
      background: $riseup_blue;
      color: $riseup_light_blue;
    }
  }
}
</style>
